import React from 'react'
import Layout from 'layouts/en'
import Seo from 'components/EN/Seo'
import Button, { ButtonGroup, ButtonGroupAlign, ButtonType } from 'components/Base/Button'
import SectionContent from 'components/SectionContent'
import * as styles from './404.module.less'

const NotFoundPage: React.FC = (props) => {
  return (
    <Layout {...props}>
      <Seo
        title="Sensors Data｜CDP and Data Analytics Platform"
        description="Powerful product analytics for mobile/web and real-time CDP that help you personalize your customer experience for better business performance"
        keywords="Data Analytics, Data Analytics Platform，Product Analytics，Customer Data Platform，Marketing Automation"
      />
      <main>
        <SectionContent className={styles.Content}>
          <img className={styles.Icon} title="logo" src="//sensorsdata.cn/assets/img/404_e3132d1.svg" />
          <p className={styles.Node}>Ooops！The page you’re looking for is lost ( T﹏T )</p>
          <ButtonGroup align={ButtonGroupAlign.Center}>
            <Button btnType={ButtonType.Primary} href="/">
              Return to Home Page
            </Button>
          </ButtonGroup>
        </SectionContent>
      </main>
    </Layout>
  )
}

export default NotFoundPage
